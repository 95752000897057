import React from 'react';
import './Dermatology.scss';
import dermatologyImage from '../../assets/servicePage/dermatology.png';

const Dermatology = () => {
    return (
        <section id="dermatologie" className='dermatology-section section-common section-bg'>
            <div className="container">
                {/* Dermatology Introduction */}
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="dermatology-text" data-aos="fade-up" data-aos-duration="2000">
                            <h2>Dermatologie</h2>
                            <p>
                                Oferim la clinica noastră o gamă extinsă de servicii dermatologice adaptate nevoilor fiecărui pacient, utilizând tehnologii de ultimă generație și abordări moderne. De la tratarea afecțiunilor cutanate complexe, cum ar fi acneea, rozaceea sau infecțiile micotice, până la proceduri estetice avansate precum microneedling cu Dermapen 4, ne concentrăm pe redarea sănătății, frumuseții și încrederii în pielea pacienților noștri. Specialiștii noștri sunt dedicați să ofere soluții personalizate, punând accent pe confort, siguranță și rezultate vizibile.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="dermatology-img" data-aos="fade-up" data-aos-duration="2000">
                            <img src={dermatologyImage} alt="Dermatologie" />
                        </div>
                    </div>
                </div>

                {/* Dermatology Services */}
                <div className="dermatology-services-section" data-aos="fade-up" data-aos-duration="2000">
                    <h3>Servicii Dermatologie</h3>
                    <ul>
                        <li>Consultații - Dermatovenerologie</li>
                        <li>Microneedling - Dermapen4</li>
                        <li>Electrocauterizări - Papiloame, Condiloame</li>
                        <li>Dermatoscopie digitală</li>
                        <li>Examinare lampă Wood - Infecții micotice, Candidoză</li>
                        <li>Injectări subcutanate - Alopecii areate, Cicatrici cheloide</li>
                    </ul>
                </div>

                {/* Dermapen 4 Section */}
                <div className="dermapen-section" data-aos="fade-up" data-aos-duration="2000">
                    <h3>Dermapen 4</h3>
                    <p>
                        Microneedling nr. 1 internațional în toate revistele de specialitate: pori măriți, acnee, cicatrici, resurfacing, bio-rejuvenare cutanată prin inducție de colagen. Dermapen 4 realizează milioane de micro-canale prin înțepături controlate digital cu niște ace extrem de subțiri. Aceasta declanșează răspunsul natural al pielii pentru auto-regenerare, cu inducție de colagen și elastină.
                    </p>

                    <h4>Beneficiile Tratamentului Dermapen</h4>
                    <ul>
                        <li>Inducția sintezei de colagen propriu și elastină - Repară și reînnoiește țesutul</li>
                        <li>Micșorarea considerabilă a porilor</li>
                        <li>Resurfacing - Uniformizează relieful pielii</li>
                        <li>Efect anti-aging durabil - Reduce ridurile fine, efect de lifting</li>
                        <li>Ameliorează cicatricile post-acneice - unul dintre foarte putinele tratamente care pot avea un efect cu adevarat notabil pentru cicatricile postacneice</li>
                        <li>Ajută la uniformizarea culorii pielii - ajuta la uniformizarea culorii pielii si reducerea foto-imbatranirii</li>
                        <li>Regenerarea părului - actiune intens regeneranta</li>
                    </ul>

                    <h4>Ce tratează Dermapen?</h4>
                    <ul>
                        <li>Acnee, rozacee, inflamații</li>
                        <li>Cicatrici acnee / post-acnee</li>
                        <li>Pori măriți</li>
                        <li>Rejuvenare facială, gât și decolteu</li>
                        <li>Pete pigmentare</li>
                        <li>Cicatrici traumă</li>
                        <li>Căderea părului</li>
                    </ul>
                    <p>
                        Sunt necesare minim 4 ședințe la intervale de 2-6 săptămâni, în funcție de protocolul stabilit pentru fiecare tip de problemă tratată.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Dermatology;
